import { type JSX } from "preact/compat";
import "./VariantSelector.scss";
import {
  getFormattedPrice,
  prices,
  changeSet,
  selectedPack,
  type SetsType,
  currencyFormater,
  activeProduct,
  activeSku,
} from "../models/products.ts";
import { useComputed } from "@preact/signals";
import { discountedSkus } from "src/data/tangem-wallets";
import { getPrice } from "../helpers.ts";

type Pack = {
  pack2: PackInner;
  pack3: PackInner;
  "pack-ring": PackInner;
};

type PackInner = {
  caption: string;
  title: string;
};
interface Props {
  pack: Pack;
}

const getPriceNumber = (price: string) => Number(price.slice(1));

export const VariantSelector = ({ pack }: Props) => {
  const handleChange: JSX.GenericEventHandler<HTMLInputElement> = (e) => {
    changeSet(e.currentTarget.value as unknown as SetsType);
  };

  const set3Price = useComputed(() => getFormattedPrice(prices[3].price));
  const set3OldPrice = useComputed(() => {
    const priceBeforeDiscount =
      activeProduct.value?.metadata?.priceBeforeDiscount;
    if (
      priceBeforeDiscount &&
      activeSku.value &&
      discountedSkus.includes(activeSku.value)
    ) {
      const activeProductPrice = getPrice(
        activeProduct.value!,
        prices[3].oldPrice.value!,
      );
      const priceBeforeDiscountNumber = getPriceNumber(priceBeforeDiscount);
      if (priceBeforeDiscountNumber !== activeProductPrice)
        return currencyFormater.format(priceBeforeDiscountNumber);
    }

    if (
      !prices[3].oldPrice.value ||
      prices[3].oldPrice.value === prices[3].price.value
    )
      return "";
    return getFormattedPrice(prices[3].oldPrice);
  });

  const set2Price = useComputed(() => getFormattedPrice(prices[2].price));
  const set2OldPrice = useComputed(() => {
    if (
      !prices[2].oldPrice.value ||
      prices[2].oldPrice.value === prices[2].price.value
    )
      return "";
    return getFormattedPrice(prices[2].oldPrice);
  });

  const setRingPrice = useComputed(() =>
    getFormattedPrice(prices["ring"].price),
  );
  const setRingOldPrice = useComputed(() => {
    if (
      !prices["ring"].oldPrice.value ||
      prices["ring"].oldPrice.value === prices["ring"].price.value
    )
      return "";
    return getFormattedPrice(prices["ring"].oldPrice);
  });

  return (
    <div class="selector">
      <fieldset class="selector__pack">
        <input
          class="selector__pack-input"
          type="radio"
          name="pack"
          value={3}
          id={"pack3"}
          onChange={handleChange}
          aria-label={pack.pack3.title}
          checked={selectedPack.value === "3"}
        />
        <label htmlFor={"pack3"} class="selector__pack-label">
          <span class="selector__pack-name">{pack["pack3"].title}</span>
          <span class="skeleton skeleton_sm">
            <span className="skeleton__bg skeleton_sm"></span>
            <span class="selector__pack-price" id={`${"pack3"}-price`}>
              {set3Price}
            </span>
          </span>

          <span class="selector__pack-caption">{pack["pack3"].caption}</span>

          <span class="selector__pack-oldprice" id={`${"pack3"}-old-price`}>
            {set3OldPrice}
          </span>
        </label>

        <input
          class="selector__pack-input"
          type="radio"
          name="pack"
          value={2}
          id={"pack2"}
          onChange={handleChange}
          aria-label={pack.pack2.title}
          checked={selectedPack.value === "2"}
        />
        <label htmlFor={"pack2"} class="selector__pack-label">
          <span class="selector__pack-name">{pack["pack2"].title}</span>
          <span class="skeleton skeleton_sm">
            <span className="skeleton__bg skeleton_sm"></span>
            <span class="selector__pack-price" id={`${"pack2"}-price`}>
              {set2Price}
            </span>
          </span>
          <span class="selector__pack-caption">{pack["pack2"].caption}</span>
          <span class="selector__pack-oldprice" id={`${"pack2"}-old-price`}>
            {set2OldPrice}
          </span>
        </label>

        <input
          class="selector__pack-input"
          type="radio"
          name="pack"
          value="ring"
          id="pack-ring"
          onChange={handleChange}
          aria-label={pack["pack-ring"].title}
          checked={selectedPack.value === "ring"}
        />
        <label htmlFor="pack-ring" class="selector__pack-label">
          <span class="selector__pack-name">{pack["pack-ring"].title}</span>
          <span class="skeleton skeleton_sm">
            <span className="skeleton__bg skeleton_sm"></span>
            <span class="selector__pack-price" id={`${"pack-ring"}-price`}>
              {setRingPrice}
            </span>
          </span>
          <span class="selector__pack-caption">
            {pack["pack-ring"].caption}
          </span>
          <span class="selector__pack-oldprice" id={`${"pack-ring"}-old-price`}>
            {setRingOldPrice}
          </span>
        </label>
      </fieldset>
    </div>
  );
};
